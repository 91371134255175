<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享区块链存证平台</p>
        <p class="header-desc">
          利用区块链技术实现数据的可信存储，使用数据多级加密和多维权限控制技术，解决传统数据易伪造、易篡改、难溯源、难校验的问题。同时平台支持一键仲裁/一键诉讼，仲裁机构或法院被授权后可直接从链上查看数据，大大提高了办事效率。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          众享区块链存证平台联合司法公证处、司法鉴定所、仲裁机构、法院等机构，建立联盟链，以电子数据为操作对象，将证据固化在众享区块链存证平台，实现电子数据的采集、存证、取证、公证、鉴定、调解、仲裁等全流程服务。在电子证据的获取和保存过程中应用区块链存证，可以有效、完整地向法庭呈现电子证据形成的全过程，让这些机构可以在未来任意时间验证某一电子数据的原始性、真实性。证据信息一经存储，任何一方无法篡改。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/certificate/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">受益角色</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/solution/certificate/role-1.png"
                />
              </div>
              <div class="title">监管机构</div>
              <div class="text">
                一旦产生纠纷，监管机构可从联盟链中的司法鉴定、审计、公证、仲裁机构随时取证监督，更便捷
              </div>
            </div></el-col
          >
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/solution/certificate/role-2.png"
                />
              </div>
              <div class="title">裁决/公证机构</div>
              <div class="text">
                仲裁/公证机构可直接从其运维的节点中获取和验证数据，把存证数据视为直接证据，不再需要第三方机构出具证明，方便易用
              </div>
            </div></el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/solution/certificate/role-3.png"
                />
              </div>
              <div class="title">存/取证人</div>
              <div class="text">
                区块链存储，确保信息完整、不被篡改，提高数据的公信力；全流程操作链上完成，减少传统方式中涉及人员多地奔波取证问题
              </div>
            </div></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/certificate/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
